import React from 'react'

const StripeTransactionFailed = () => {
  return (
    <div className='text-center mt-5  rounded p-2' style={{maxWidth:'550px', marginLeft:'auto', marginRight:'auto',  borderBottom:'2px solid red',}}>
        <h6 className='text-lead'>Sorry</h6>
        <img src='https://assets-v2.lottiefiles.com/a/6a1f0bce-1178-11ee-a807-ebab7337127e/5rsPIZBogk.gif' style={{width:'320px', height:'320px'}} />
        <p className='text-danger'>Transactions is failed</p>
        <p>You can close this tab and Go back to your previous Screen and try agin </p>
    </div>
  )
}

export default StripeTransactionFailed