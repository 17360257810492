// routes
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// import { useAuth } from '../context/authcontext'

import Main from "../views.xz/main-xz";
import Events from "../views.events/algorand-impact-summit-india-hyderabad-2024";
import TieDelhiContent from "../views.events/tie-delhi-ncr";
import StripeTransactionSuccess from "../content/transfer/stripe-transaction-success";
import StripeTransactionFailed from "../content/transfer/stripe-transaction-failed";

const routes = [
  { route: "/", content: <Main />, auth: false },
  { route: "/tgs-tie-global-summit-india-bangalore-2024", content: <Events />, auth: false },
  { route: "/tie-delhi-ncr", content: <TieDelhiContent />, auth: false },
  { route: "/algorand-impact-summit-india-hyderabad-2024", content: <Events />, auth: false },
  { route: "/transaction/success", content: <StripeTransactionSuccess />, auth: false },
  { route: "/transaction/failed", content: <StripeTransactionFailed />, auth: false },
];

export default function RouteX() {
  // const { user } = useAuth()

  return (
    <Routes>
      {routes.map((item, i) => (
        // true ? (
        //   <Route
        //     key={i}
        //     path={item.route}
        //     element={!user ? <Navigate to='/' replace /> : item.content}
        //   />
        // ) :
        <Route key={i} path={item.route} element={item.content} />
      ))}
    </Routes>
  );
}
