import { useEffect, useState } from "react";

import AttendenceModeModule from "./attendence-mode";
import AirlineModule from "./flight-travel";
import TransportationModule from "./road-travel";
import AssetsList from "./assets-list";
import RegistrationModule from "./user-registration";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import ProgressBar from "./progress-bar";
import FormNeeded from "../webx/form-needed";
import CarbonFootprint from "./carbon-footprint";
import { TransfersAssetsCreate } from "../../services/algorand-summit/summit-trxn-asset-transfer";

export default function ImpactSummitModule(props) {
  const [currentStep, setCurrentStep] = useState(0);
  const [start, setStart] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [paymentSuccessful, setPaymentSuccesfull] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [airTravelCarbon, setAirtravelCarbon] = useState(0);
  const [localTravelCarbon, setLocaltravelCarbon] = useState(0);
  const [totalCarbon, seTotalCarbon] = useState(0);
  const [progress, setProgress] = useState("0%");
  const [asset, setAsset] = useState();
  const [user, setUser] = useState(null);
  const [id, setId] = useState(0);
  const [link, setLink] = useState(null);

  const totalSteps = 4;

  
  const handleStep = async (step) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    const asset = JSON.parse(localStorage.getItem("indx"));
    // console.log("asset", asset);
    setUser(asset);
    setAsset(asset);
  }, [currentStep]);

  useEffect(() => {
    seTotalCarbon(airTravelCarbon + localTravelCarbon);
  }, [airTravelCarbon, localTravelCarbon]);

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
      setProgress(`${((currentStep + 1) / totalSteps) * 100}%`);
    } else {
      setSubmit(true);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setProgress(`${((currentStep - 1) / totalSteps) * 100}%`);
      setSubmit(false);
    }
  };

  useEffect(() => {
    if (currentStep == 4) {
      const fetchTrxn = async () => {
        var datx = {
          user: asset?.item,
        };
        const result = await TransfersAssetsCreate({ data: datx });
        // console.log(result);
      };
      fetchTrxn();
    }
  }, [paymentSuccessful]);

  function shareOnLinkedIn() {
    const shareUrl =
      "https://terano.io/events/algorand-impact-summit-india-hyderabad-2024"; // Replace with your actual content URL
    const shareText = "Check out this awesome content!"; // Replace with your desired share text
    const summary = "Join the Algorand Impact Summit in Hyderabad, 2024!"; // Optional
    const source = "Terano Events,"; // Optional

    // Construct the LinkedIn share URL
    const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      shareUrl
    )}&title=${encodeURIComponent(shareText)}&summary=${encodeURIComponent(
      summary
    )}&source=${encodeURIComponent(source)}`;

    // Open the share URL in a new window or tab
    window.open(linkedinShareUrl, "_blank");
  }

  function shareOnX() {
    const shareUrl =
      "https://terano.io/events/algorand-impact-summit-india-hyderabad-2024"; // Replace with your actual content URL
    const shareText = `I Offset ${totalCarbon} kgCOe via Terano - India's 1st Carbon Market Exchange, Join Me - Offset Your Carbon Footprint for Algorand Impact Summit 2024`; // Replace with your desired tweet text
    const hashtags = "NetZero,ClimateChange,Sustainability,CarbonCredits,India"; // Optional hashtags, separated by commas
    const via = "teranoio @0xSaurav @manojvyas"; // Optional Twitter username to attribute the tweet to (without @)

    // Construct the X (Twitter) share URL
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      shareUrl
    )}&text=${encodeURIComponent(shareText)}&hashtags=${encodeURIComponent(
      hashtags
    )}&via=${encodeURIComponent(via)}`;

    // Open the share URL in a new window or tab
    window.open(twitterShareUrl, "_blank");
  }

  return (
    <>
      {/* for first step */}
      <div className="back-color-wite" style={{ backgroundColor: "" }}>
        <div className="d-none">
          <p className="text-lead">
            We are thrilled to have you join us as we come together to exchange
            ideas, foster connections, and drive innovation. This year, we are
            taking an extra step towards making a positive impact—not just
            through the ideas shared here, but also by contributing to a
            sustainable future.
          </p>
          <p className="text-lead">
            In recognition of the environmental impact of travel and large
            gatherings, we are committed to offsetting the carbon footprint of
            this conference. Through initiatives such as planting trees,
            supporting renewable energy projects, and funding sustainable
            practices, we are making efforts to leave a greener world behind.
          </p>
        </div>

        {/* user create save to local storage after creation */}
        <div className={currentStep == 0 ? "" : "d-none"}>
          <h2 className="text-lead">Get Started</h2>
          <ProgressBar
            currentStep={currentStep}
            totalSteps={totalSteps}
            progress={progress}
          />
          <WebbDividerSmall />
          <RegistrationModule
            stepchange={handleStep}
            handleNext={handleNext}
            handleBack={handleBack}
            stepCount={currentStep}
          />
        </div>

        {/* attendence mode 1 */}
        <div className={currentStep == 1 ? "" : "d-none"}>
          <h2 className="text-lead">
            Attendence Mode <FormNeeded />
          </h2>
          <ProgressBar
            currentStep={currentStep}
            totalSteps={totalSteps}
            progress={progress}
          />
          <WebbDividerSmall />
          <AttendenceModeModule
            stepchange={handleStep}
            handleNext={handleNext}
            handleBack={handleBack}
            stepCount={currentStep}
          />
        </div>

        {/* flight transport 2*/}
        <div className={currentStep == 2 ? "" : "d-none"}>
          <WebbDividerSmall />
          <div className="d-flex justify-content-between">
            <h2 className="text-lead">Air Travel</h2>{" "}
            <span className=" text-primary cursor" onClick={handleNext}>
              Skip
            </span>
          </div>
          <ProgressBar
            currentStep={currentStep}
            totalSteps={totalSteps}
            progress={progress}
          />
          <CarbonFootprint
            currentStep={currentStep}
            totalCarbon={totalCarbon}
          />

          <AirlineModule
            setAirtravelCarbon={setAirtravelCarbon}
            stepchange={handleStep}
            handleNext={handleNext}
            handleBack={handleBack}
            stepCount={currentStep}
          />
        </div>

        {/* Road Transport 3*/}
        <div className={currentStep == 3 ? "" : "d-none"}>
          <WebbDividerSmall />
          <div className="d-flex justify-content-between">
            <h2 className="text-lead">local Travel</h2>{" "}
            <span className=" text-color-tint cursor" onClick={handleNext}>
              Skip
            </span>
          </div>
          <ProgressBar
            currentStep={currentStep}
            totalSteps={totalSteps}
            progress={progress}
          />
          <CarbonFootprint
            currentStep={currentStep}
            totalCarbon={totalCarbon}
          />
          <WebbDividerSmall />
          <TransportationModule
            stepchange={handleStep}
            setLocaltravelCarbon={setLocaltravelCarbon}
            handleNext={handleNext}
            handleBack={handleBack}
            stepCount={currentStep}
          />
        </div>

        {/* Asset Listing Module 4*/}
        <div className={currentStep == 4 ? "" : "d-none"}>
          <div className={start ? "d-none" : ""}>
            <WebbDividerSmall />
            <h2 className="text-lead">Select Renewable Project</h2>
          </div>
          <CarbonFootprint
            currentStep={currentStep}
            totalCarbon={totalCarbon}
          />
          <AssetsList
            setCurrentStep={setCurrentStep}
            totalCarbon={totalCarbon}
            handleNext={handleNext}
            handleBack={handleBack}
            stepCount={currentStep}
            setPaymentSuccesfull={setPaymentSuccesfull}
            setPaymentFailed={setPaymentFailed}
            setLink={setLink}
            setStart={setStart}
          />
        </div>

        <div className={paymentSuccessful ? "" : "d-none"}>
          <div className="border-none p-3">
            <div className="rounded-xd">
              <div className="text-center">
                <img
                  src="https://www.architecturaldigest.in/wp-content/themes/cntraveller/images/check-circle.gif"
                  style={{ width: "150px", height: "150px" }}
                />
                <p className="text-secondary text-small p-2 mb-2">
                  Successfully Minted
                </p>
                <WebbDividerMedium />
                <div className="d-flex justify-content-center ">
                  <div className="">
                    <button
                      className="btn btn-success w-auto px-4 rounded-wd text-small "
                      onClick={() => shareOnX()}
                    >
                      Share on X (Twitter)
                    </button>
                  </div>

                  <div className="mx-3"></div>

                  <div className="">
                    <button
                      className="btn btn-outline-success w-auto px-4 rounded-wd text-small "
                      onClick={() =>
                        window.open(link)
                      }
                    >
                      View On Blockchain
                    </button>
                  </div>

                  <div className="text-end d-none">
                    <button
                      className="btn btn-outline-primary w-auto rounded-xx text-small "
                      onClick={() => shareOnLinkedIn()}
                    >
                      Share On LinkedIn
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className=""></div>
          </div>
        </div>

        <div className={paymentFailed ? "" : "d-none"}>
          <div className="border-none p-3">
            <div className="rounded-xd">
              <div className="text-center">
                <img
                  src="https://miro.medium.com/v2/resize:fit:810/1*OkeqV425CNZUQT2HSkTnJA.png"
                  style={{ width: "150px", height: "150px" }}
                />
                <p className="text-secondary text-small p-2 mb-2">
                  Payment failed
                </p>
              </div>
            </div>
          </div>
        </div>

        {/*  redirect to carts screen */}
      </div>
    </>
  );
}
